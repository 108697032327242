var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"cancellation-policies"}},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('app-data-table',{ref:"cancellationPolicyTable",attrs:{"table-name":"cancellation-policy-table","items":_vm.cancellationPolicies,"fields":_vm.cancellationPoliciesFields,"page":_vm.page,"per-page":_vm.numberOfItemsPerPage,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc,"small":_vm.$store.getters['app/mdAndDown'],"manual-pagination":"","top-table":{
        showAdd: _vm.$can('CANCELLATION_POLICY_ADD'),
        showSearch: _vm.$can('CANCELLATION_POLICY_VIEW'),
        searchFilterOptions: _vm.searchFilterOptions,
        searchText: _vm.name,
      },"actions-table":{
        showDuplicate: _vm.$can('CANCELLATION_POLICY_ADD'),
        showEdit: _vm.$can('CANCELLATION_POLICY_EDIT'),
        showDelete: _vm.$can('CANCELLATION_POLICY_DELETE'),
        showShow: _vm.$can('CANCELLATION_POLICY_VIEW'),
      },"bottom-table":{ totalCount: _vm.pagination.totalItems }},on:{"update:sortBy":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"table-select-per-page":function($event){;(_vm.numberOfItemsPerPage = $event), _vm.search()},"table-search-text":function($event){(_vm.name = $event), _vm.search()},"table-add":function($event){return _vm.$router.push({ name: 'cancellationPolicyAdd' })},"duplicate":function($event){return _vm.$router.push({ name: 'cancellationPolicyDuplicate', params: { id: $event.item.id } })},"edit":function($event){return _vm.$router.push({ name: 'cancellationPolicyEdit', params: { id: $event.item.id } })},"delete":function($event){return _vm.deleteCancellationPolicy($event.item)},"show":function($event){return _vm.$router.push({ name: 'cancellationPolicyShow', params: { id: $event.item.id } })},"pagination":function($event){(_vm.page = $event), _vm.search()}},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
      var item = ref.item;
return [(_vm.$can('CANCELLATION_POLICY_EDIT'))?_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{ name: 'cancellationPolicyEdit', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")]):(_vm.$can('CANCELLATION_POLICY_VIEW'))?_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{ name: 'cancellationPolicyShow', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))])]}},{key:"cell(description)",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.description))])]}},{key:"cell(default)",fn:function(ref){
      var item = ref.item;
return [(item.default)?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(_vm._s(_vm.$t('common.yes')))]):_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(_vm._s(_vm.$t('common.no')))])]}},{key:"cell(status)",fn:function(ref){
      var item = ref.item;
return [(item.status === 'active')?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(_vm._s(_vm._f("enumTranslate")(item.status,'cancellation_policy_status')))]):_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(_vm._s(item.status))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }