<template>
  <div id="cancellation-policies">
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <app-data-table
        ref="cancellationPolicyTable"
        table-name="cancellation-policy-table"
        :items="cancellationPolicies"
        :fields="cancellationPoliciesFields"
        :page="page"
        :per-page="numberOfItemsPerPage"
        :sort-by.sync="sort.sortBy"
        :sort-desc.sync="sort.sortDesc"
        :small="$store.getters['app/mdAndDown']"
        manual-pagination
        :top-table="{
          showAdd: $can('CANCELLATION_POLICY_ADD'),
          showSearch: $can('CANCELLATION_POLICY_VIEW'),
          searchFilterOptions,
          searchText: name,
        }"
        :actions-table="{
          showDuplicate: $can('CANCELLATION_POLICY_ADD'),
          showEdit: $can('CANCELLATION_POLICY_EDIT'),
          showDelete: $can('CANCELLATION_POLICY_DELETE'),
          showShow: $can('CANCELLATION_POLICY_VIEW'),
        }"
        :bottom-table="{ totalCount: pagination.totalItems }"
        @table-select-per-page=";(numberOfItemsPerPage = $event), search()"
        @table-search-text="(name = $event), search()"
        @table-add="$router.push({ name: 'cancellationPolicyAdd' })"
        @duplicate="$router.push({ name: 'cancellationPolicyDuplicate', params: { id: $event.item.id } })"
        @edit="$router.push({ name: 'cancellationPolicyEdit', params: { id: $event.item.id } })"
        @delete="deleteCancellationPolicy($event.item)"
        @show="$router.push({ name: 'cancellationPolicyShow', params: { id: $event.item.id } })"
        @pagination="(page = $event), search()"
      >
        <!-- CELL -->
        <!--name-->
        <template #cell(name)="{ item }">
          <b-link v-if="$can('CANCELLATION_POLICY_EDIT')" :to="{ name: 'cancellationPolicyEdit', params: { id: item.id } }" class="font-weight-bold">
            {{ item.name }}
          </b-link>
          <b-link v-else-if="$can('CANCELLATION_POLICY_VIEW')" :to="{ name: 'cancellationPolicyShow', params: { id: item.id } }" class="font-weight-bold">
            {{ item.name }}
          </b-link>
          <span v-else class="font-weight-bold">{{ item.name }}</span>
        </template>
        <!--description-->
        <template #cell(description)="{ item }">
          <div>{{ item.description }}</div>
        </template>
        <!--default-->
        <template #cell(default)="{ item }">
          <b-badge v-if="item.default" pill variant="light-success">{{ $t('common.yes') }}</b-badge>
          <b-badge v-else pill variant="light-danger">{{ $t('common.no') }}</b-badge>
        </template>
        <!--status-->
        <template #cell(status)="{ item }">
          <b-badge v-if="item.status === 'active'" pill variant="light-success">{{ item.status|enumTranslate('cancellation_policy_status') }}</b-badge>
          <b-badge v-else pill variant="light-danger">{{ item.status }}</b-badge>
        </template>
      </app-data-table>
    </b-card>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import AppDataTable from '@/components/AppDataTable.vue'

import { deleteCancellationPolicyRequest } from '@/request/globalApi/requests/cancellationPolicyRequests'

export default defineComponent({
  name: 'CancellationPolicies',

  components: { AppDataTable },

  setup(_props, ctx) {
    const { $i18n } = ctx.root

    const searchFilterOptions = [
      { text: $i18n.tc('cancellation_policy.title', 2), value: 'cancellationPolicies' },
    ]
    const cancellationPoliciesFields = [
      { key: 'name', label: $i18n.tc('cancellation_policy.title', 2), thStyle: 'min-width: 20vw', sortable: true },
      { key: 'description', label: $i18n.t('cancellation_policy.description'), thClass: 'w-100', sortable: true },
      { key: 'status', label: $i18n.t('common.status'), tdClass: 'text-center' },
      { key: 'default', label: $i18n.t('common.default'), tdClass: 'text-center' },
    ]
    const sort = {
      sortBy: null,
      sortDesc: null,
    }

    return {
      searchFilterOptions,
      cancellationPoliciesFields,
      sort,
    }
  },

  computed: {
    ...mapState('cancellationPolicy', ['cancellationPoliciesLoading', 'cancellationPolicies', 'pagination', 'queryParams', 'sorting']),

    ...mapFields('cancellationPolicy', [
      'queryParams.page',
      'queryParams.numberOfItemsPerPage',
      'search.name',
      'search.description',
    ]),
  },

  methods: {
    ...mapActions('cancellationPolicy', ['fetchCancellationPolicies', 'clearCancelationPoliciesShowed', 'setSort']),

    deleteCancellationPolicy(cancellationPolicy) {
      deleteCancellationPolicyRequest(cancellationPolicy.id, cancellationPolicy.name).then(response => {
        response && this.fetchCancellationPolicies(true)
      })
    },

    search() {
      this.fetchCancellationPolicies(true)
    },
  },

  watch: {
    queryParams() {
      this.fetchCancellationPolicies(true)
    },

    sort: {
      handler(value) {
        this.setSort(value)
      },
      deep: true,
    },
  },

  mounted() {
    if (this.$route.params.refresh) {
      this.fetchCancellationPolicies(this.$route.params.refresh)
    } else {
      this.fetchCancellationPolicies()
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.matched[0].path !== '/cancellation-policy') {
      this.clearCancelationPoliciesShowed()
    }
    next()
  },
})
</script>
